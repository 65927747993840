<template>
  <div>
    <h2 class="content-block">Collections</h2>

    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0"
      :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key="id">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="350" title="Seri Detayları" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="code" />
            <DxItem data-field="name" />
            <DxItem data-field="serie" />
          </DxItem>
        </DxForm>
      </dx-editing>

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />
      <dx-column data-field="id" caption="#" :allow-editing="false" :visible="false" />
      <dx-column data-field="code" caption="Code" />
      <dx-column data-field="name" caption="Name" />
      <dx-column data-field="serie" caption="Serie">
        <DxLookup :data-source="categorieTypes" value-expr="name" display-expr="name" />
      </dx-column>

      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
  DxLookup,
} from "devextreme-vue/data-grid";

import { DxItem } from 'devextreme-vue/form';

const categorieTypes = [
  { name: "BRICK SERIES" },
  { name: "STONE COLLECTION" },
  { name: "CONCRETE COLLECTION" },
  { name: "WOOD COLLECTION" },
  { name: "TILE SERIES" },
  { name: "POLYURETHANE SERIES" },
];

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}Category/Read`,
  insertUrl: `${globalSettings}Category/Create`,
  updateUrl: `${globalSettings}Category/Update`,
  deleteUrl: `${globalSettings}Category/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

export default {
  data() {
    return {
      categorieTypes,
      dataSource,
      salePriceFormat: {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 2,
      },
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Add New Series",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ]
    };
  },
  created() {

  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxToolbar,
    DxItem,
    DxLookup
  }
};
</script>
