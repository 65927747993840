<template>
  <div>
    <h2 class="content-block">Waybills</h2>

    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :show-borders="false"
      :focused-row-enabled="false" :column-auto-width="true" :column-hiding-enabled="true" key="id"
      @row-prepared="onRowPrepared" @row-expanding="onRowExpanding">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="500" title="Waybill Detail" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="invoiceId" :col-span="2" />
            <DxItem data-field="date" />
            <DxItem data-field="note"/>
            <DxItem data-field="driver"/>
            <DxItem data-field="driverIdentity"/>
          </DxItem>
        </DxForm>
      </dx-editing>
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />
      <dx-column :visible="false" data-field="id" caption="#" :allow-editing="false" />
      <dx-column data-field="code" caption="Code" />
      <dx-column data-field="date" caption="Waybill Date" data-type="date" />      
      <DxColumn data-field="invoiceId" caption="Proforma">
        <DxLookup :data-source="invoiceData" value-expr="id" display-expr="project" />
      </DxColumn>
      <dx-column data-field="note" caption="Vehicle Information" />
      <dx-column data-field="driver" caption="Driver Name Surname" />
      <dx-column data-field="driverIdentity" caption="Driver TC" />
      <dx-column type="buttons">
        <DxButton text="Print" :on-click="sendClick" />
        <DxButton name="edit" />
        <DxButton name="delete" />
      </dx-column>

      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxLookup,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
  DxButton,
} from "devextreme-vue/data-grid";

import { DxItem } from 'devextreme-vue/form';

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}WayBill/Read`,
  insertUrl: `${globalSettings}WayBill/Create`,
  updateUrl: `${globalSettings}WayBill/Update`,
  deleteUrl: `${globalSettings}WayBill/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const invoiceData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const currencyTypes = ["₺","$", "€"];

export default {
  data() {
    return {
      dataSource,
      invoiceData,
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Add New WayBill",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ],
      currencyTypes
    };
  },
  methods: {
    sendClick(e) {
      const id = e.row.data.id;
      const routeData = this.$router.resolve({ name: "print-waybill", params: { id } });
      window.open(routeData.href, '_blank');
    },
    calculateAmount(rowData) {
      return rowData.generalTotalPrice - rowData.paymentTotalPrice;
    },
    onRowPrepared(e) {
      if (e.rowType == 'data' && e.data.generalTotalPrice > 0 && e.data.generalTotalPrice == e.data.paymentTotalPrice) {
        e.rowElement.style.backgroundColor = '#A7D397';
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
      else if (e.rowType == 'data' && e.data.generalTotalPrice > 0) {
        e.rowElement.style.backgroundColor = '#D8003240';
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1);
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxLookup,
    DxEditing,
    DxButton,
    DxPopup,
    DxForm,
    DxToolbar,
    DxItem,
  }
};
</script>
