<template>
  <div>
    <h2 class="content-block">Projects</h2>

    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :show-borders="false"
      :focused-row-enabled="false" :column-auto-width="true" :column-hiding-enabled="true" key="id"
      @row-prepared="onRowPrepared" @row-expanding="onRowExpanding">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="820" title="Project Detail" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="project" :col-span="2" />
            <DxItem data-field="customerId" :col-span="2" />
            <DxItem data-field="date" />
            <DxItem data-field="expDate" />
            <DxItem data-field="additionalServices" />
            <DxItem data-field="servicePrice" />
            <DxItem data-field="currency" />
            <DxItem data-field="kdvPercent" />
            <DxItem data-field="note" />
          </DxItem>
        </DxForm>
      </dx-editing>
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />
      <dx-column :visible="false" data-field="id" caption="#" :allow-editing="false" />
      <dx-column data-field="code" caption="Code" />
      <dx-column data-field="project" caption="Project Name" />
      <dx-column data-field="date" caption="Waybill Date" data-type="date" />
      <dx-column data-field="expDate" caption="Deadline" data-type="date" />
      <DxColumn data-field="customerId" caption="Customer">
        <DxLookup :data-source="customerData" value-expr="id" display-expr="name" />
      </DxColumn>
      <dx-column data-field="totalPrice" caption="Total Amount" :allow-editing="false" :allow-updating="false" />
      <dx-column data-field="servicePrice" caption="Shipping Amount" />
      <dx-column data-field="generalTotalPrice" caption="Grand Total" :allow-editing="false" />
      <dx-column name="calc" caption="Remaining Amount" :calculate-cell-value="calculateAmount" :allow-editing="false" />
      <dx-column data-field="additionalServices" caption="Shipping Location" />
      <dx-column data-field="kdvPrice" caption="Total VAT" :allow-editing="false" />
      <dx-column data-field="kdvPercent" caption="VAT Rate">
        <DxLookup :data-source="kdvTypes" value-expr="value" display-expr="value" />
      </dx-column>
      <dx-column data-field="note" caption="Note" />
      <dx-column data-field="currency" caption="Currency">
        <DxLookup :data-source="currencyTypes" />
      </dx-column>
      <dx-column type="buttons">
        <DxButton text="Print" :on-click="sendClick" />
        <DxButton text="Archive" :on-click="archivleClick" />
        <DxButton name="edit" />
        <DxButton name="delete" />
      </dx-column>

      <!-- <dx-master-detail :enabled="true" template="master-detail" />

      <template #master-detail="{ data }">
        <InvoiceDetail :master-detail-data="data" />
      </template> -->

      <!-- <template #chart-cell-template="{ data }">
        <DxButton icon="print" @click="sendClick(data)" />
      </template> -->

      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxLookup,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
  DxButton,
} from "devextreme-vue/data-grid";

import { DxItem } from 'devextreme-vue/form';

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Read`,
  insertUrl: `${globalSettings}Invoice/Create`,
  updateUrl: `${globalSettings}Invoice/Update`,
  deleteUrl: `${globalSettings}Invoice/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const customerData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Customer/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const currencyTypes = ["₺", "$", "€"];
const kdvTypes = [{
  value: 0
}, {
  value: 1
}, {
  value: 8
}, {
  value: 10
}, {
  value: 18
}, {
  value: 20
}];

import axios from "axios";
export default {
  data() {
    return {
      dataSource,
      customerData,
      kdvTypes,
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Add New Project",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ],
      currencyTypes
    };
  },
  methods: {
    archivleClick(e) {
      const id = e.row.data.id;
      axios.get(`${appInfo.baseUrl}Invoice/Archive/` + id, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }

      })
        .then(() => {
          this.$refs.dataGrid.instance.refresh();
        }).catch(error => {
          console.log(error)
        })
    },
    sendClick(e) {
      const id = e.row.data.id;
      const routeData = this.$router.resolve({ name: "print-invoice", params: { id } });
      window.open(routeData.href, '_blank');
    },
    calculateAmount(rowData) {
      return rowData.generalTotalPrice - rowData.paymentTotalPrice;
    },
    onRowPrepared(e) {
      if (e.rowType == 'data' && e.data.generalTotalPrice > 0 && e.data.generalTotalPrice == e.data.paymentTotalPrice) {
        e.rowElement.style.backgroundColor = '#A7D397';
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
      else if (e.rowType == 'data' && e.data.generalTotalPrice > 0) {
        e.rowElement.style.backgroundColor = '#D8003240';
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1);
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxLookup,
    DxEditing,
    DxButton,
    DxPopup,
    DxForm,
    DxToolbar,
    DxItem,
  }
};
</script>
