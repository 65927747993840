export default {
  title: "Proforma Application",
  baseUrl: "https://billing-api.app.benimligim.com/",
  // baseUrl: "https://localhost:7113/",
  company:{
    name: "Exen Panel Sanayi ve Ticaret Ltd. Şti.",
    authorized:"Yetkili Kişi",
    address: "Adres",
    phone: "Telefon",
    email: "Email",
  }
};
