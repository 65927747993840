export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Collections",
    path: "/categories",
    icon: "product",
    isAdmin: true
  },
  {
    text: "Products",
    path: "/products",
    icon: "product",
    isAdmin: true
  },
  {
    text: "Customers",
    path: "/customers",
    icon: "user"
  },
  {
    text: "Projects",
    icon: "folder",
    path: "/invoices",
  },
  {
    text: "Add Proforma",
    path: "/invoice-details",
    icon: "file"
  },
  {
    text: "Payments",
    path: "/invoice-payments",
    icon: "money"
  },
  {
    text: "Waybills",
    path: "/waybills",
    icon: "airplane"
  },
  // {
  //   text: "İrsaliye Detayları",
  //   path: "/waybills-details",
  //   icon: "bulletlist"
  // },
  {
    text: "Users",
    path: "/users",
    icon: "user",
    isAdmin: true
  }
];
